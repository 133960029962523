import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import logo from '../assest/img/NameDefence.png';
import Instagram from '../assest/img/Instagram.png';
import X from '../assest/img/X.png';
import LinkedIn from '../assest/img/LinkedIn.png';

export default function Footer() {
    return (
        <div id="aboutUs" className="d-flex justify-content-center align-items-center">
            <Container fluid>
                <Row className='py-3 py-md-5 justify-content-center'>
                    <Col xs={12} md={4} className='d-flex justify-content-center justify-content-md-start align-items-center mb-3 mb-md-0'>
                        <img src={logo} alt="Logo" className="img-fluid" style={{maxWidth: '200px'}} />
                    </Col>
                    <Col xs={12} md={8} className='d-flex flex-column flex-md-row justify-content-center justify-content-md-end align-items-center'>
                        <div className='d-flex justify-content-center justify-content-md-end mx-3 mb-3 mb-md-0'>
                            <a href="https://www.instagram.com/prientegroup/" target="_blank" rel="noopener noreferrer">
                                <img src={Instagram} alt="Instagram" className='mx-2' style={{width: '30px', height: '30px'}} />
                            </a>
                            <a href="https://twitter.com/prientegroup" target="_blank" rel="noopener noreferrer">
                                <img src={X} alt="X" className='mx-2' style={{width: '30px', height: '30px'}} />
                            </a>
                            <a href="https://www.linkedin.com/company/priente/" target="_blank" rel="noopener noreferrer">
                                <img src={LinkedIn} alt="LinkedIn" className='mx-2' style={{width: '30px', height: '30px'}} />
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row className='mb-2 d-flex justify-content-center px-3'>
                    <Col xs={12} className='text-center'>
                        <p className='mb-0' style={{ color: "gray", fontSize: '12px' }}>© 2024 Priente Software. Tüm Hakları Saklıdır.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
