import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Earth from './Earth';
import img from '../assest/img/rowImg.svg';
import img2 from '../assest/img/rowImg2.svg';
import img3 from '../assest/img/rowImg3.svg';
import img4 from '../assest/img/rowImg4.svg';
import img5 from '../assest/img/rowImg5.svg';
import Slider from "react-slick";

export default function Home() {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <Container fluid id='home'>
      <Row className='align-items-center' style={{ minHeight: '100vh', position: 'relative', overflow: 'hidden' }}>
        <Col xs={12} md={6} className="d-flex justify-content-center flex-column p-4" style={{ zIndex: 2 }}>
          <h1 className="responsive-heading" style={{ fontWeight: 'bold' }}>
            Dynamic Name<br />
            Server and Firewall
          </h1>
          <p className="responsive-text" style={{ color: "gray" }}>Expert Solutions for Current Cyber Threats</p>
          <br />
          <Row className="justify-content-start">
            <Col xs={12} lg={10} className="d-flex flex-column flex-md-row">
              <input
                type="text"
                className="form-control mb-2 mb-md-0"
                placeholder="E-Mail Address"
                style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', border: '1px solid', flex: 1 }}
                value={inputValue}
                onChange={handleInputChange}
              />
              <Button href='/auth' variant='outline-light' className='ms-md-2 mt-2 mt-md-0'>Request a Demo</Button>
            </Col>
          </Row>
          <p className='subtitle mt-2' style={{ fontSize: '10px', color: 'light gray' }}>You are considered to have accepted data sharing.</p>
        </Col>

        <Col xs={12} md={6} style={{ position: 'relative', height: '100vh' }}>
          <div style={{ 
            position: 'absolute', 
            top: '50%', 
            right: '-10%', 
            width: '110%', 
            height: '110%', 
            transform: 'translateY(-50%)' 
          }}>
            <Earth />
          </div>
        </Col>
      </Row>

      <Row className="mt-5 d-flex justify-content-center">
        <Col>
          <Slider {...settings}>
            <div>
              <img src={img} alt="First slide" className="img-fluid" style={{ filter: "brightness(1000%)" }}/>
            </div>
            <div>
              <img src={img2} alt="Second slide" className="img-fluid" style={{ filter: "brightness(1000%)" }}/>
            </div>
            <div>
              <img src={img3} alt="Third slide" className="img-fluid" style={{ filter: "brightness(1000%)" }}/>
            </div>
            <div>
              <img src={img4} alt="Fourth slide" className="img-fluid" style={{ filter: "brightness(1000%)" }}/>
            </div>
            <div>
              <img src={img5} alt="Fifth slide" className="img-fluid" style={{ filter: "brightness(1000%)" }}/>
            </div>
          </Slider>
        </Col>
      </Row>
    </Container>
  )
}