import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import World from '../assest/3d/earth.glb';

const Earth = () => {
  const mountRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const modelRef = useRef(null);
  const cameraRef = useRef(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    const width = mountRef.current.clientWidth;
    const height = mountRef.current.clientHeight;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    cameraRef.current = camera;
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

    renderer.setSize(width, height);
    mountRef.current.appendChild(renderer.domElement);

    const light = new THREE.AmbientLight(0xffffff, 1);
    scene.add(light);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(5, 5, 5);
    scene.add(directionalLight);

    const updateModelSize = () => {
      if (modelRef.current) {
        const scale = isMobile ? 20 : 60;
        modelRef.current.scale.set(scale, scale, scale);

        if (isMobile) {
          modelRef.current.position.set(-40, -50, 0);
          camera.position.set(0, 0, 15);
        } else {
          modelRef.current.position.set(-80, -100, 0);
          camera.position.set(0, 0, 20);
        }
        camera.lookAt(modelRef.current.position);
      }
    };

    const loader = new GLTFLoader();
    loader.load(
      World,
      (gltf) => {
        modelRef.current = gltf.scene;
        scene.add(modelRef.current);

        updateModelSize();

        function animate() {
          requestAnimationFrame(animate);
          if (modelRef.current) {
            modelRef.current.rotation.y += 0.005;
          }
          renderer.render(scene, camera);
        }
        animate();
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + '% yüklendi');
      },
      (error) => {
        console.error('Model yüklenirken bir hata oluştu:', error);
      }
    );

    const handleResize = () => {
      const newWidth = mountRef.current.clientWidth;
      const newHeight = mountRef.current.clientHeight;
      camera.aspect = newWidth / newHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(newWidth, newHeight);
      updateModelSize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', checkMobile);
      if (mountRef.current) {
        mountRef.current.removeChild(renderer.domElement);
      }
    };
  }, [isMobile]);

  return <div ref={mountRef} style={{ width: '100%', height: '100%' }} />;
};

export default Earth;